import { Box, Button, Modal, Typography } from "@mui/material";

import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import DoneIcon from '@mui/icons-material/Done';
import { isValidElement } from "react";

export default function Alerta({ open, setOpen, severity, message, onClick }) {
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableAutoFocus
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                maxWidth: '90%',
                boxSizing: 'border-box',
                bgcolor: 'background.paper',
                borderRadius: 1,
                boxShadow: 24,
                p: 4,

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                {severity === 'error' && <ErrorIcon sx={{
                    color: '#f44336', // Color rojo más suave
                    fontSize: 80,
                }} />}
                {severity === 'warning' && <WarningIcon sx={{
                    color: '#ff9800', // Color naranja más suave
                    fontSize: 80,
                }} />}
                {severity === 'info' && <InfoIcon sx={{
                    color: '#2196f3', // Color azul más suave
                    fontSize: 80
                }} />}
                {severity === 'success' && <DoneIcon sx={{
                    color: '#4caf50', // Color verde más suave
                    fontSize: 80
                }} />}
                {isValidElement(message) ?
                    message :
                    <Typography variant="h5" sx={{
                        mt: 2,
                        fontSize: 24,
                        textAlign: 'center',
                        color: "black"
                    }}>
                        {message}
                    </Typography>
                }
                <Button
                    variant="contained"
                    onClick={() => {
                        onClick && onClick();
                        setOpen(false)
                    }}
                    sx={{ mt: 2 }}
                >
                    Aceptar
                </Button>
            </Box>
        </Modal>
    )
}