import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';

const ProgressiveImage = ({ src, alt, onClick, style }) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <div style={{ position: 'relative', display: 'inline-block', ...style }}>
            {!loaded && (
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: 8,
                    }}
                />
            )}
            <img
                src={src}
                alt={alt}
                onClick={onClick}
                onLoad={() => setLoaded(true)}
                style={{
                    filter: loaded ? 'none' : 'blur(4px)',
                    opacity: loaded ? 1 : 0,
                    transition: 'all 1s ease-in-out',
                    display: 'block',
                    width: '100%',
                    borderRadius: 8,
                    cursor: 'pointer',
                }}
                loading='lazy'
            />
        </div>
    );
};

export default ProgressiveImage;