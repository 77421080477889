import React, { useState, useRef, useEffect } from 'react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { API_BASE_URL, fetchData } from '../../services/fetchData';
import { Container, Box, CircularProgress, Typography, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Lightbox from 'yet-another-react-lightbox';
import Download from 'yet-another-react-lightbox/plugins/download';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import ProgressiveImage from './ProgessiveImage';

export default function Fotos() {
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageIndex, setCurrentIndex] = useState(0);
    const [grupoId, setGrupoId] = useState('');
    const fullscreenRef = useRef(null);

    const { data: gruposData, isLoading: isLoadingGrupos } = useQuery({
        queryKey: ['grupos'],
        queryFn: async () => {
            const response = await fetchData('multimedia/fotos/grupos/');
            return response;
        },
        refetchOnWindowFocus: false,
    });

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch } = useInfiniteQuery({
        queryKey: ['fotos', grupoId],
        queryFn: async ({ pageParam = 1 }) => {
            const response = await fetchData(`multimedia/fotos${grupoId ? `?grupo_id=${grupoId}&` : '?'}page=${pageParam}`);
            return response;
        },
        getNextPageParam: (lastPage, allPages) => lastPage.next ? allPages.length + 1 : undefined,
        refetchOnWindowFocus: false,
    });

    const fotos = data?.pages.flatMap(page => page.results) || [];
    const loadMoreRef = useRef(null);

    const openLightbox = (index) => {
        setCurrentIndex(index);
        setIsOpen(true);
        document.getElementById("header").style.display = "none";
    };

    const closeLightbox = () => {
        setIsOpen(false);
        document.getElementById("header").style.display = "flex";
    };

    const gotoPrevious = () => setCurrentIndex((prev) => (prev > 0 ? prev - 1 : fotos.length - 1));
    const gotoNext = () => setCurrentIndex((prev) => (prev < fotos.length - 1 ? prev + 1 : 0));

    const handleGrupoChange = (event) => {
        setGrupoId(event.target.value);
        refetch();
    };


    useEffect(() => {
        if (!hasNextPage || isFetchingNextPage) return;
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    fetchNextPage();
                }
            },
            { rootMargin: '100px' }
        );
        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }
        return () => {
            if (loadMoreRef.current) {
                observer.unobserve(loadMoreRef.current);
            }
        };
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);
    return (
        <Container maxWidth="lg" sx={{ padding: 2, marginTop: 2 }}>
            <Typography variant="h4" gutterBottom>Fotos</Typography>
            <FormControl fullWidth sx={{ marginBottom: 4, mt: 4 }}>
                <InputLabel id="grupo-select-label">Seleccionar la fecha</InputLabel>
                <Select
                    labelId="grupo-select-label"
                    value={grupoId}
                    label="Seleccionar la fecha"
                    onChange={handleGrupoChange}
                >
                    <MenuItem value="">Todas las fotos</MenuItem>
                    {gruposData?.map((grupo) => (
                        <MenuItem key={grupo.id} value={grupo.id}>{grupo.nombre}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {isLoading ? (
                <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />
            ) : (
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                    <Masonry>
                        {fotos.map((foto, index) => (
                            // <Box key={index} sx={{ marginBottom: 2 }} onClick={() => openLightbox(index)}>
                            //     <img
                            //         src={`${API_BASE_URL.slice(0, -4)}${foto.imagen}`}
                            //         alt={foto.descripcion}
                            //         style={{ width: '100%', borderRadius: 8, cursor: 'pointer' }}
                            //         loading="lazy"
                            //     />
                            // </Box>
                            <Box key={index} onClick={() => openLightbox(index)}>
                                <ProgressiveImage
                                    src={`${API_BASE_URL.slice(0, -4)}${foto.imagen}`}
                                    alt={foto.descripcion}
                                    onClick={() => openLightbox(index)}
                                />
                            </Box>
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            )}
            {isFetchingNextPage && <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />}
            {/* {hasNextPage && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => fetchNextPage()}
                    sx={{
                        display: 'block',
                        margin: '20px auto',
                        padding: '10px 20px',
                        fontSize: '16px',
                        color: '#220015',
                        borderRadius: '8px',
                        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.3)',
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: '#d4a646',
                        },
                    }}
                >
                    Cargar más fotos
                </Button>
            )} */}
            {isFetchingNextPage && (
                <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />
            )}
            <div ref={loadMoreRef} style={{ height: '1px' }} />
            {isOpen && (
                <Lightbox
                    open={isOpen}
                    close={closeLightbox}
                    slides={fotos.map((foto) => ({
                        src: `${API_BASE_URL.slice(0, -4)}${foto.imagen}`,
                        alt: foto.descripcion,
                        width: 800,
                        height: 600,
                        download: {
                            url: `${API_BASE_URL.slice(0, -4)}${foto.imagen}`,
                            filename: foto.descripcion
                        }
                    }))}
                    index={currentImageIndex}
                    on={{ view: ({ index }) => setCurrentIndex(index) }}
                    onPrev={gotoPrevious}
                    onNext={gotoNext}
                    plugins={[Download, Fullscreen, Counter]}
                    fullscreen={{ ref: fullscreenRef }}
                    counter={{ container: { style: { top: "unset", bottom: 0 } } }}
                    styles={{ container: { backgroundColor: 'rgba(0, 0, 0, 0.5)', backdropFilter: 'blur(10px)' } }}
                />
            )}
        </Container>
    );
}