import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import FoodTruckIcon from './assets/foodtruck.png'
import ConcesionariaIcon from './assets/concesionaria.png'
import GastronomicoIcon from './assets/gastronomico.png'
import MoveToLocation from './MoveToLocation';
import EmprendedoresIcon from './assets/emprendedores.png';
import PinIcon from './assets/pin.png';
import { useMediaQuery } from '@mui/material';

const foodTruckIcon = new L.Icon({
    iconUrl: FoodTruckIcon,
    iconSize: [50, 50],
    iconAnchor: [15, 30],
});

const consecionariaIcon = new L.Icon({
    iconUrl: ConcesionariaIcon,
    iconSize: [50, 50],
    iconAnchor: [15, 30],
});

const gastronomicoIcon = new L.Icon({
    iconUrl: GastronomicoIcon,
    iconSize: [50, 50],
    iconAnchor: [15, 30],
});

const emprendedoresIcon = new L.Icon({
    iconUrl: EmprendedoresIcon,
    iconSize: [50, 50],
    iconAnchor: [15, 30],
});

const pinIcon = new L.Icon({
    iconUrl: PinIcon,
    iconSize: [50, 50],
    iconAnchor: [15, 30],
});



export default function MapComponent(props) {
    const { ubicacion } = props;

    const isMobile = useMediaQuery('(max-width:600px)');

    const renderMarker = () => {
        if (!ubicacion) return null; // Si no hay ubicación, no renderiza nada.

        switch (ubicacion.categoria) {
            case "FOOD TRUCK":
                return <Marker position={[ubicacion.lat, ubicacion.lng]} icon={foodTruckIcon} />;
            case "CONCESIONARIAS":
                return <Marker position={[ubicacion.lat, ubicacion.lng]} icon={consecionariaIcon} />;
            case "PATIO GASTRONÓMICO":
                return <Marker position={[ubicacion.lat, ubicacion.lng]} icon={gastronomicoIcon} />;
            case "EMPRENDEDORES":
                return <Marker position={[ubicacion.lat, ubicacion.lng]} icon={emprendedoresIcon} />;

            default:
                return <Marker position={[ubicacion.lat, ubicacion.lng]} icon={pinIcon} />;
        }
    };

    return (
        <MapContainer center={[ubicacion?.lat || -32.41469454047811, ubicacion?.lng || -63.2423299238929]} zoom={ubicacion?.zoom || 13} style={{ height: '100%', width: isMobile ? "100%" : 'calc(100% + 300px)' }}>
            <TileLayer
                url="http://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                attribution="Map data ©2024 Google"
                preferCanvas={true}
                keepBuffer={10}
            />

            <MoveToLocation ubicacion={ubicacion} />
            {renderMarker()}
        </MapContainer>
    );
};