import Festival from '../pages/festival/Festival';
import Recorrido from '../pages/recorrido/Recorrido';
import Prensa from '../pages/prensa/Prensa';
import Valores from '../pages/valores/Valores';
import VillaMaria from '../pages/villamaria/VillaMaria';
import Fotos from '../pages/fotos/Fotos';
import EnVivo from '../pages/enVivo/EnVivo';


const routes = [
    { path: '/', element: <Festival />, title: "Festival de peñas", sidebar: true, },
    { path: '/en-vivo', element: <EnVivo />, title: "En vivo", sidebar: true },
    { path: '/recorrido', element: <Recorrido />, title: "Recorrido peñero", sidebar: true },
    { path: '/fotos-recorrido', element: <Fotos />, title: "Fotos Recorrido", sidebar: true },
    { path: '/villa-maria', element: <VillaMaria />, title: "Villa María", sidebar: true },
    // { path: '/prensa', element: <Prensa />, title: "Prensa", sidebar: true },
    // { path: '/nuevos-valores', element: <Valores />, title: "Certamen Nuevos valores", sidebar: true },
];

export default routes;