import { Box, Button, Typography } from "@mui/material";

import Logo107 from "./assets/107logo.png"
import LogoEntupse from "./assets/logoentupse.png"
import LogoFixer from "./assets/logofixer.png"
import LogoAgenda from "./assets/logo52.png"

import LogoMuni from "./assets/logomuni.svg"

import QRFixer from "./assets/qrfixer.png"
import QR107 from "./assets/107qr.png"
import QRentupse from "./assets/qrentupse.png"
import Bus from "./assets/bus.jpg"
import Ter from "./assets/ter.png"
import { useEffect } from "react";

export default function VillaMaria() {
    useEffect(() => {
        document.title = "Villa María"
    }, [])

    const herramientas = [
        {
            herramienta: "Servicios",
            nombre: "Villa María",
            logo: LogoMuni,
            descripcion: "Encuentra profesionales de confianza con facilidad. Accede a perfiles detallados, con ubicación cercana y comunicación directa para una experiencia de contratación sin complicaciones ni retrasos. Fixer, la plataforma que conecta calidad con eficiencia.",
            link: "https://villamaria.gob.ar/",

        },
        {
            herramienta: "Emergencias",
            nombre: "Emergencias",
            // iframe: "http://localhost:3001",
            logo: Logo107,
            descripcion: "Esta aplicación le permitirá utilizar los servicios de emergencias médicas de 107 Villa María. Una vez registrado en la plataforma usted podrá convocar los servicios presionando solamente el botón rojo de emergencia.",
            qr: QR107,
            links: [
                {
                    nombre: "Google Play",
                    url: "https://play.google.com/store/apps/details?id=avilio.villa.maria&hl=es_AR"
                },
                {
                    nombre: "App Store",
                    url: "https://apps.apple.com/ar/app/107-vm/id6505078770?l=en-GB"
                }
            ]
        },
        {
            herramienta: "Servicios",
            nombre: "Servicios",
            qr: QRFixer,
            logo: LogoFixer,
            descripcion: "Encuentra profesionales de confianza con facilidad. Accede a perfiles detallados, con ubicación cercana y comunicación directa para una experiencia de contratación sin complicaciones ni retrasos. Fixer, la plataforma que conecta calidad con eficiencia.",
            link: "https://fixersoluciones.com/?demo=true"
        },
        {
            herramienta: "Servicios",
            nombre: "Eventos",
            qr: QRFixer,
            logo: LogoAgenda,
            descripcion: "Encuentra profesionales de confianza con facilidad. Accede a perfiles detallados, con ubicación cercana y comunicación directa para una experiencia de contratación sin complicaciones ni retrasos. Fixer, la plataforma que conecta calidad con eficiencia.",
            link: "https://villamaria.agenda52.com/",
            backgroundColor: "#e9722e"
        },
        {
            herramienta: "Estacionamiento",
            nombre: "Estacionamiento",
            qr: QRentupse,
            logo: LogoEntupse,
            descripcion: "Esta herramienta le permitira colaborar con el ordenamiento vial, conocer recorridos y frecuencias del transporte urbano y administrar sus minutos de estacionamiento vehicular por medio de una billetera electrónica.",
            links: [
                {
                    nombre: "Google Play",
                    url: "https://play.google.com/store/apps/details?id=ar.utn.frvm.mvm&hl=es_AR"
                },
                {
                    nombre: "App Store",
                    url: "https://apps.apple.com/ar/app/villa-mar%C3%ADa-ciudad/id1579192962?l=en-GB"
                }
            ]
        },
        {
            nombre: "Terminal",
            link: "https://api.whatsapp.com/send/?phone=5493534214362&text&type=phone_number&app_absent=0",
            logo: Ter,
        },
        {
            nombre: "Buses",
            link: "https://micronauta.dnsalias.net/web/urbano/?conf=transbus",
            logo: Bus,
        },
    ]


    return (
        <Box sx={{
            minHeight: "100vh",
            width: "100%",
            overflowX: "hidden",
        }}>
            <Box sx={{
                display: "grid",
                gridTemplateColumns: {
                    xs: "repeat(auto-fill, minmax(100%, 1fr))",
                    sm: "repeat(auto-fill, minmax(200px, 1fr))",
                },
                gap: "50px",
                justifyItems: "center",
                alignItems: "center",
                width: "100%",
                px: { sm: "200px" },
                pt: "50px",
                boxSizing: "border-box",
            }}>
                {herramientas.map((herramienta) => (
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                        textAlign: "center",
                        width: "100%",
                        // hover
                        "&:hover": {
                            transform: "scale(1.1)"
                        },

                        transition: "all 0.3s"
                    }}
                        key={herramienta.nombre}
                    >
                        <Button
                            key={herramienta.nombre}
                            variant="contained"
                            color="primary"
                            sx={{
                                width: "230px",
                                height: "230px",
                                backgroundColor: herramienta.backgroundColor || "white",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                                borderRadius: "10px",
                                padding: "20px",
                                fontSize: "14px",
                                boxSizing: "border-box",
                                cursor: "pointer",

                            }}
                            onClick={() => {
                                window.open(herramienta.links ? herramienta.links[0].url : herramienta.link)
                            }}
                        >
                            {herramienta.logo ?
                                <img src={herramienta.logo} alt={herramienta.nombre} style={{
                                    width: "100%",
                                    maxHeight: "100%",
                                    objectFit: "contain"
                                }} />
                                :
                                <h3>{herramienta.nombre}</h3>
                            }
                        </Button>
                        <Typography variant="body1" sx={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "bold",
                            lineHeight: "20px",
                            letterSpacing: "0.25px"
                        }}>
                            {herramienta.nombre}
                        </Typography>
                    </Box>
                ))}
            </ Box>
        </Box>
    )
}