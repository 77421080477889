import { Box, IconButton } from "@mui/material";
import Header from "./Header";
import Background from "./Background";
import Footer from "./Footer";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


export default function Layout({ children }) {
    const headerHeight = 80;
    const mainRef = useRef(null);

    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        mainRef.current.scrollTop = 0;
    }, [location.pathname]);

    return (
        <Box
            ref={mainRef}
            sx={{
                display: 'flex', flexDirection: "column",
                overflowY: !['/recorrido'].includes(location.pathname) && "auto",
                height: window.innerHeight,
                width: window.innerWidth,
                boxSizing: 'border-box',
                '&::-webkit-scrollbar': {
                    width: '5px', // Define el ancho de la scrollbar
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#D4A646', // Define el color de la scrollbar
                    borderRadius: '10px', // Opcional: redondea los bordes de la scrollbar
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent', // Define el color de fondo de la pista de la scrollbar
                },
            }}>
            <Header height={headerHeight} />
            <Background />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: `${headerHeight}px`,
                boxSizing: 'border-box',
                alignItems: 'center',
            }}>
                {children}
            </Box>
            {!['/recorrido', '/en-vivo'].includes(location.pathname) &&
                <IconButton sx={{
                    position: "fixed",
                    bottom: 20,
                    right: 20,
                    backgroundColor: "rgba(65, 36, 54,0.7)",
                    backdropFilter: "blur(10px)",
                    height: 80,
                    width: 80,
                    zIndex: 1000,
                }}
                    href="https://wa.me/543534061318"
                    target="_blank"
                >
                    <WhatsAppIcon sx={{ color: "#fff", fontSize: 40 }} />
                </IconButton>
            }
            <Footer />
        </Box>
    )
}