import { Box } from "@mui/material";
import LogoEnte from "../assets/logo_ente.png";
import LogoMVM from "../assets/logo_mvm.svg";
import LogoPeniero from "../assets/logo_peniero.png";
import { useLocation } from "react-router-dom";
import Banner from "./Banner";

export default function Footer() {

    const location = useLocation();

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            py: '100px',
            gap: "100px"

        }}>
            {!['fotos-recorrido', 'villa-maria'].includes(location.pathname.split('/')[1]) && <Banner />}
            <Box sx={{
                width: '100%',
                display: 'flex',
                gap: { xs: '80px', md: '150px' },
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <img src={LogoMVM} alt="Logo MVM" style={{ width: '200px' }} onClick={() => window.location.assign("https://villamaria.gob.ar/")} />
                <img src={LogoEnte} alt="Logo Ente" style={{ width: '200px' }} />
                {location.pathname.includes('recorrido') && <img src={LogoPeniero} alt="Logo Ente" style={{ width: '250px' }} />}
            </Box>
        </Box>
    )
}