import { useState, useEffect } from "react";
import { Box } from "@mui/material";

export default function EnVivo() {
    const [currentVideo, setCurrentVideo] = useState('https://youtube.com/embed/CoizzCAR6Qs?feature=share');


    const maxWidth = window.innerWidth  - 20;
    const maxHeight = window.innerHeight - 100;
    let height = maxHeight;
    
    if (maxHeight > maxWidth * 9 / 16) {
         height = maxWidth * 9 / 16;
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            boxSizing: 'border-box',
            padding: '10px',
        }}>
            {/* Video Actual */}
            <Box sx={{ width: '100%', position: 'relative', aspectRatio: '16/9' }}>
                <iframe
                    src={currentVideo}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                        width: maxWidth,
                        height: height,
                        borderRadius: '12px',
                        border: "2px solid #D4A646",
                    }}
                ></iframe>
            </Box>

            {/* Sección de Videos Anteriores */}
            {/* <Box sx={{
                mt: 2,
                width: '100%',
                overflowX: 'auto',
                display: 'flex',
                gap: 2,
                pb: 1,
            }}>
                {previousVideos.map((video, index) => (
                    <Box
                        key={index}
                        onClick={() => setCurrentVideo(video)}
                        sx={{
                            flexShrink: 0,
                            cursor: 'pointer',
                            border: currentVideo === video ? '2px solid #D4A646' : '2px solid transparent',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            width: '40%',
                            maxWidth: 200,
                            aspectRatio: '16/9',
                        }}
                    >
                        <iframe
                            src={video}
                            title={`Video ${index}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{
                                width: '100%',
                                height: '100%',
                                pointerEvents: 'none', // Evita que se reproduzca automáticamente al hacer hover/click
                            }}
                        ></iframe>
                    </Box>
                ))}
            </Box> */}
        </Box>
    );
}