import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Box, Typography, Button } from "@mui/material";

import routes from "../routes/routes";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";
import { useState, useEffect } from "react";

import Logo from '../assets/logo.png';
import Background from "./Background";


export default function Sidebar(props) {
    const { open, setOpen } = props;

    const navigate = useNavigate();
    const location = useLocation();

    const { logged, userData, logout } = useAppContext();

    function handleNavigate(path) {
        navigate(path);
        setOpen(false);
    }

    const [currentRoute, setCurrentRoute] = useState(routes.find(route => route.path === location.pathname));

    useEffect(() => {
        setCurrentRoute(routes.find(route => route.path === location.pathname));
    }, [location.pathname]);

    const DrawerList = (
        <Box sx={{
            width: "100%", height: "100%", display: "flex", flexDirection: "column", overflow: "hidden", backdropFilter: "blur(3px)",

        }}>

            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <img src={Logo} alt="Logo" style={{ height: 50, margin: "16px auto" }} />
            </Box>
            <List disablePadding>
                {routes.filter(route => route.sidebar && (route.private ? logged : true)).map(seccion => (
                    <ListItem key={seccion.title} disablePadding>
                        <ListItemButton onClick={() => handleNavigate(seccion.path)}>
                            <Button variant="contained" onClick={() => navigate('/recorrido')} sx={{
                                backgroundColor: 'transparent',
                                width: '100%',
                                border: location.pathname === seccion.path ? '2px solid #D4A646' : 'none',
                                color: '#FFFFFF',
                                borderRadius: '8px',
                                height: '50px',
                                fontWeight: 'bold',
                            }}>
                                {seccion.title}
                                {seccion.path === "/en-vivo" &&
                                    <Box sx={{
                                        width: "10px",
                                        height: "10px",
                                        backgroundColor: "red",
                                        borderRadius: "50%",
                                        marginLeft: "10px",
                                        animation: "pulse 2s infinite",


                                    }} />
                                }
                            </Button>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            {logged && (
                <Box sx={{ mt: 'auto', p: 2 }}>
                    <Divider />
                    <Button
                        variant="contained"
                        onClick={logout}
                        sx={{
                            backgroundColor: 'transparent',
                            width: '100%',
                            color: '#FFFFFF',
                            borderRadius: '8px',
                            height: '50px',
                            fontWeight: 'bold',
                        }}
                    >
                        Cerrar Sesión
                    </Button>
                </Box>
            )}
        </Box>
    );

    return (
        <Drawer
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                "& .MuiDrawer-paper": {
                    width: 300,
                    overflow: "hidden",
                },
                zIndex: 10000,
            }}
        >
            <Background />
            {DrawerList}
        </Drawer>
    );
}