import React, { useState, useEffect, useMemo } from 'react';
import { Container, Typography, CircularProgress, Grid, Card, CardMedia, CardContent, Box, Modal, IconButton, Button } from '@mui/material';
import Background from '../../layout/Background';
import CloseIcon from '@mui/icons-material/Close';
import { API_BASE_URL, fetchData } from '../../services/fetchData';

const Artista = React.memo((props) => {
    const { artista } = props;

    const [open, setOpen] = useState(false);
    const [nota, setNota] = useState({
        texto: "",
        imagen: "",
    });

    const [artistaSeleccionado, setArtistaSeleccionado] = useState(null);

    const imageUrl = useMemo(() => {
        if (!artista) return "";
        getNota();
        const imageObj = artista.ft
            ? artista.artistas[0].artista.images[0]
            : artista.artista.images[0];

        if (!imageObj) return "";

        const imageUrl = imageObj.url.startsWith('http')
            ? imageObj.url
            : `/assets/artistas/${imageObj.url}`;

        // Agregar un parámetro de caché si el navegador no la guarda
        return `${imageUrl}?cache=1`;

    }, [artista]);

    function getNota() {
        fetchData("artistas/",
            {
                method: "POST",
                body: JSON.stringify({
                    nombre: artista.ft ? artista.artistas[0].artista.name : artista.artista.name,
                }),
            }
        ).then((data) => {
            if (data.nota) {
                setNota({
                    imagen: data.nota.imagen,
                    texto: data.nota.texto,
                })
            }
        });
    }

    useEffect(() => {
        console.log("re-render");
    }, []);


    return (
        <Box>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                alignItems: "center",
                width: "140px",
            }}
            >

                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2,
                        boxSizing: 'border-box',

                    }}
                >
                    <Button sx={{
                        width: '140px',
                        height: '140px',
                        borderRadius: '50%',
                        border: '3px solid #D4A646',
                        overflow: "hidden",
                        cursor: 'pointer',
                        ":hover": {
                            opacity: 0.6,
                        },
                    }}
                        onClick={() => setOpen(true)}

                    >
                        <img src={imageUrl} alt={artista.ft ? artista.artistas[0].artista.name : artista.artista.name} style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '50%',
                        }}
                        />
                    </Button>
                    <Typography sx={{
                        color: '#FFFFFF',
                        fontSize: '20px',
                        textWrap: 'wrap',
                        textAlign: 'center',
                        width: "100%",

                    }}>{artista.ft ? artista.artistas[0].artista.name + ' y ' + artista.artistas[1].artista.name : artista.artista.name}</Typography>
                </Box>

            </Box>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus
                disableEnforceFocus
                disableRestoreFocus
                sx={{
                    zIndex: 100000,
                }}
            >

                <Box sx={{
                    width: window.innerWidth - 50,
                    height: window.innerHeight - 50,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    overflow: "hidden",
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '8px',
                }}>
                    <Background />
                    <IconButton onClick={() => setOpen(false)} sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        backdropFilter: 'blur(10px)',
                        color: '#FFFFFF',
                        borderRadius: '50%',
                        "&:hover": {
                            backgroundColor: '#D4A646',
                        }
                    }}>
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 6,
                        overflowY: "auto",
                        overflowX: "hidden",
                        height: "100%",
                        width: "100%",
                        padding: { xs: "20px", sm: '40px' },
                        boxSizing: 'border-box',
                        '&::-webkit-scrollbar': {
                            width: '10px', // Define el ancho de la scrollbar
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#D4A646', // Define el color de la scrollbar
                            borderRadius: '10px', // Opcional: redondea los bordes de la scrollbar
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent', // Define el color de fondo de la pista de la scrollbar
                        },
                    }} >
                        <Box sx={{
                            display: "flex",
                            alignItems: { xs: 'center', sm: "flex-end" },
                            justifyContent: { xs: "space-between", sm: "flex-start" },
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 4,

                        }}>
                            <img src={imageUrl

                            } alt={artista.ft ? artista.artistas[0].artista.name : artista.artista.name
                            } style={{
                                width: '300px',
                                height: '300px',
                                objectFit: 'cover',
                                borderRadius: '50%',
                            }} />
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-end",
                            }}>
                                <Typography variant="h2" gutterBottom sx={{
                                    color: '#FFFFFF',
                                    fontSize: { xs: '38px', sm: '54px' },
                                    fontWeight: 'bold',
                                }}>{artista.ft ? artista.artistas[0].artista.name + ' y ' + artista.artistas[1].artista.name : artista.artista.name}</Typography>
                            </Box>
                        </Box>
                        {nota.texto &&
                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: "column",
                                mt: 4,
                                width: '100%',
                                padding: 2,
                                boxSizing: 'border-box',
                                borderRadius: '8px',
                                backgroundColor: "rgba(128, 64, 56, .5)",
                                gap: 1,
                                border: "1px solid rgba(128, 64, 56, .4s)",
                            }}>
                                <Typography sx={{
                                    fontSize: { xs: '10px', sm: '16px' },
                                    fontWeight: 'bold',
                                }}>Festival 57°</Typography>
                                <Box sx={{
                                    display: 'flex',
                                    gap: 2,

                                }}>
                                    <img src={`${API_BASE_URL.slice(0, -4)}${nota.imagen}`} alt="Nota" style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'cover',
                                        borderRadius: '50%',
                                        maxWidth: "40%",
                                    }} />
                                    <Typography sx={{
                                        color: '#FFFFFF',
                                        fontSize: { xs: '16px', sm: '24px' },
                                        fontStyle: 'italic',
                                    }}>{nota.texto}</Typography>
                                </Box>
                            </Box>
                        }
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}>
                            <Typography gutterBottom sx={{
                                color: '#FFFFFF',
                                fontWeight: 'bold',
                                fontSize: { xs: '24px', sm: '32px' },
                            }}>Canciones Populares</Typography>
                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 4,
                                borderRadius: '8px',
                                width: '100%',
                            }}>

                                {artista.ft ? artista.artistas[0].artista.tracks?.slice(0, 9).map(track => (
                                    <Box sx={{
                                        height: "352px",
                                        width: { xs: "100%", sm: "calc(100% / 3 - 22px)" },
                                    }}
                                        key={track.id}
                                    >
                                        < iframe src={`https://open.spotify.com/embed/track/${track.id}`} width="100%" height="400px" frameBorder="0" allow="encrypted-media"></iframe>
                                    </Box>
                                )) :
                                    artista.tracks?.slice(0, 9).map(track => (
                                        <Box sx={{
                                            height: "352px",
                                            width: { xs: "100%", sm: "calc(100% / 3 - 22px)" },
                                        }}
                                            key={track.id}
                                        >
                                            < iframe src={`https://open.spotify.com/embed/track/${track.id}`} width="100%" height="400px" frameBorder="0" allow="encrypted-media"></iframe>
                                        </Box>
                                    ))}
                            </Box>
                        </Box>
                    </Box >
                </Box >
            </Modal >
        </Box >
    );
}, (prevProps, nextProps) => prevProps.artista.id === nextProps.artista.id);

export default Artista;