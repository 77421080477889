import { createTheme } from '@mui/material/styles';

const theme = createTheme({


    palette: {
        primary: {
            main: '#fff',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#FFFFFF',
        },
        secondary: {
            main: '#1E88E5',
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        color: '#FFFFFF', // Color del texto
                        '&.Mui-disabled': {
                            color: '#BDBDBD', // Color del texto deshabilitado
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: '#FFFFFF', // Color del label
                        '&.Mui-disabled': {
                            color: '#BDBDBD', // Color del label deshabilitado
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#FFFFFF', // Color del borde
                        },
                        '&:hover fieldset': {
                            borderColor: '#FFFFFF', // Color del borde al pasar el mouse
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#FFFFFF', // Color del borde al enfocar
                        },
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF', // Color del texto de Typography
                },
            },
        },
        MuiMenu: {
            styleOverrides: {

                paper: {
                    backdropFilter: 'blur(10px)', // Filtro de desenfoque
                    backgroundColor: "rgba(34,0,21,.9)",
                },
            },
        },
    },

});

export default theme;