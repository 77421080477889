export const API_BASE_URL = 'https://anfi.villamaria.gob.ar/api/'; // Reemplaza con tu URL base

const headers = {
    'Content-Type': 'application/json',
    // 'Authorization': 'Bearer YOUR_TOKEN' // Reemplaza con tu token de autenticación si es necesario
};

export const fetchData = async (endpoint, options = {}) => {
    try {
        const response = await fetch(`${API_BASE_URL}${endpoint}`, {
            ...options,
            headers: {
                ...headers,
                ...options.headers,
            },
        });

        let responseJson = await response.json();

        if (!response.ok) {
            throw new Error(`${responseJson.error}`);
        }

        return responseJson;

    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};