import React, { useRef, useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { API_BASE_URL, fetchData } from "../services/fetchData";

export default function Banner() {
    const [logos, setLogos] = useState([

    ]);

    const containerRef = useRef(null);
    const [repeatedLogos, setRepeatedLogos] = useState([]);
    const isMobile = useMediaQuery("(max-width: 600px)");

    const gap = 5; // Distance between logos

    useEffect(() => {
        fetchData("sponsors/").then((data) => {
            setLogos(data.sponsors);

        }
        );
    }, []);

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const containerWidth = container.offsetWidth;

        const singleLogoWidth = 100; // Approximation of logo width
        const logoWithGap = singleLogoWidth + gap;

        // Calculate how many additional logos are needed to fill the container
        let logosNeeded = Math.ceil(containerWidth / logoWithGap);
        if (logosNeeded % logos.length !== 0) {
            logosNeeded = Math.ceil(logosNeeded / logos.length) * logos.length;
        }

        // Create a repeated array of logos to fill the container
        const filledLogos = [];
        for (let i = 0; i < logosNeeded; i++) {
            filledLogos.push({ ...logos[i % logos.length], id: `logo-${i}` });
        }

        // Store logos for rendering two identical containers
        setRepeatedLogos(filledLogos);
    }, [logos]);

    const renderLogos = () => {
        return repeatedLogos.map((logo) => (
            <Box
                key={logo.id}
                sx={{
                    flexShrink: 0,
                    height: "100px",
                    width: "auto",
                    marginRight: "10px",
                    cursor: "pointer",
                }}
                onClick={() => window.location.assign(logo.url)}

            >
                <img
                    src={`https://anfi.villamaria.gob.ar${logo.logo}`}
                    alt={logo.nombre}
                    style={{
                        height: "100px",
                        width: "auto",
                        display: "block",
                        borderRadius: "6px",
                    }}
                />
            </Box>
        ));
    };

    return (
        <Box
            ref={containerRef}
            sx={{
                width: "100%",
                overflow: "hidden",
                position: "relative",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    whiteSpace: "nowrap",
                }}
            >
                {/* Render both containers dynamically */}
                {Array(2)
                    .fill(null)
                    .map((_, idx) => (
                        <Box
                            key={`container-${idx}`}
                            sx={{
                                display: "flex",
                                gap: gap,
                                alignItems: "center",
                                animation: `scroll ${isMobile ? '40' : '60'}s linear infinite`,
                                mr: gap,
                            }}
                        >
                            {renderLogos()}
                        </Box>
                    ))}
            </Box>

            {/* CSS animation for infinite scrolling */}
            <style>
                {`
          @keyframes scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        `}
            </style>
        </Box>
    );
}