import React, { useEffect, useState, useMemo } from 'react';
import Artista from './Artista';
import { Box, Typography, Button, useMediaQuery } from '@mui/material';
import Background from '../../layout/Background';
import dias from "./dias.json";

const Festival = () => {
    const [diaSelecionado, setDiaSeleccionado] = useState(() => dias[0]); // Inicializar con memoización
    const isMobile = useMediaQuery('(max-width: 600px)');

    useEffect(() => {
        document.title = "Festival de Peñas Villa María 2025";
    }, []);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            boxSizing: 'border-box',
            justifyContent: "flex-end",
            position: 'relative',
        }}>
            {/* Contenedor de Spotify */}
            <Box sx={{
                width: { xs: "calc(100% - 20px)", sm: '25%' },
                boxSizing: 'border-box',
                height: { xs: "400px", sm: 'calc(100vh - 100px)' },
                mt: '10px',
                ml: '10px',
                mr: { xs: "10px", sm: "0" },
                backgroundColor: "rgba(128, 64, 56, 255)",
                borderRadius: '12px',
            }}>
                <iframe
                    src="https://open.spotify.com/embed/playlist/1Lqnm8SO2QnxwCGqja2bpR"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="encrypted-media"
                ></iframe>
            </Box>

            {/* Contenedor de Artistas */}
            <Box sx={{
                width: { xs: "100%", sm: "75%" },
                p: "10px",
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
            }}>
                {/* Botones de selección de día */}
                <Box sx={{
                    display: "flex",
                    gap: "10px"
                }}>
                    {dias.map(dia => (
                        <Button
                            key={dia.dia}
                            variant="contained"
                            sx={{
                                backgroundColor: diaSelecionado.dia === dia.dia ? '#D4A646' : 'rgba(255,255,255,0.1)',
                                backdropFilter: 'blur(10px)',
                                color: '#FFFFFF',
                                borderRadius: '8px',
                                height: '50px',
                                flexGrow: 1,
                                fontWeight: 'bold',
                            }}
                            onClick={() => setDiaSeleccionado(dia)}
                        >
                            {isMobile ? dia.dia.slice(0, 3) + '\n' + dia.dia.slice(-2) : dia.dia}
                        </Button>
                    ))}
                </Box>

                {/* Lista de Artistas */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    pt: '20px',
                    pb: '20px',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: "wrap",
                        justifyContent: "space-evenly",
                        alignItems: 'flex-start',
                        boxSizing: 'border-box',
                        py: '20px',
                        width: '100%',
                        px: '10px',
                        gap: '10px',
                    }}>
                        {dias.filter(dia => dia.dia === diaSelecionado.dia)[0].artistas.map(artista => (
                            <Artista key={artista.artista ? artista.artista.id : artista.artistas[0].artista.id}
                                artista={artista} />
                        ))}
                    </Box>

                    {/* Botón de comprar entradas */}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: '30px',
                    }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'rgba(255,255,255,0.1)',
                                color: '#FFFFFF',
                                borderRadius: '8px',
                                px: '20px',
                                py: '10px',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                backdropFilter: 'blur(10px)',
                                "&:hover": {
                                    backgroundColor: '#D4A646',
                                }
                            }}
                            onClick={() => window.location.assign("https://www.edenentradas.ar/event/festival-internacional-de-peas-villa-maria")}
                        >
                            Comprar entradas
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Festival;