import React, { createContext, useContext, useState } from 'react';
import Alerta from '../components/alerta/Alerta';

const AlertaContext = createContext();

export const useAlert = () => {
    return useContext(AlertaContext);
};

export const AlertaProvider = ({ children }) => {
    const [alertaProps, setAlertaProps] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const showAlerta = (severity, message) => {
        setAlertaProps({
            open: true,
            severity,
            message,
        });
    };

    const closeAlerta = () => {
        setAlertaProps(prev => ({
            ...prev,
            open: false,
        }));
    };

    return (
        <AlertaContext.Provider value={showAlerta} closeAlerta={closeAlerta}>
            {children}
            <Alerta
                open={alertaProps.open}
                setOpen={closeAlerta}
                severity={alertaProps.severity}
                message={alertaProps.message}
            />
        </AlertaContext.Provider>
    );
};

export const useAlerta = () => {
    return useContext(AlertaContext);
}