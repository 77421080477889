import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

export default function MoveToLocation(props) {
    const { ubicacion } = props;

    const map = useMap();

    useEffect(() => {
        if (ubicacion) {
            map.flyTo([ubicacion.lat, ubicacion.lng], ubicacion.zoom, { duration: 1 });
        } else {
            map.flyTo([-32.41469454047811, -63.2423299238929], 13, { duration: 1 });
        }

    }, [ubicacion, map]);

    return null;
};