import MapComponent from "./MapComponent";
import { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Box, Button, Typography, AccordionDetails, useMediaQuery, IconButton, Modal, Icon } from '@mui/material';

import { API_BASE_URL, fetchData } from "../../../services/fetchData";

import SwipeableViews from 'react-swipeable-views';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function Mapa() {
    const [categorias, setCategorias] = useState([]);
    const [modalPatio, setModalPatio] = useState({
        open: false,
        data: null,
        propuestaIndex: 0,
    });

    const [ubicacion, setUbicacion] = useState();

    const isMobile = useMediaQuery('(max-width:600px)');
    const [open, setOpen] = useState();

    useEffect(() => {
        // Lee el archivo CSV desde la carpeta assets
        fetchData('comercios/')
            .then(data => data.comercios)
            .then(data => {

                const formattedData = data.map(row => {
                    return {
                        name: row.nombre,
                        lat: row.latitud,
                        lng: row.longitud,
                        categoria: row.categoria,
                        patio: row.patio,
                        sitio: row.sitio,
                        zoom: 18,
                    };
                });

                console.log(formattedData);



                // Agrupa las ubicaciones por categoría
                const groupedData = formattedData.reduce((acc, curr) => {
                    if (!acc[curr.categoria]) {
                        acc[curr.categoria] = [];
                    }
                    acc[curr.categoria].push(curr);
                    return acc;
                }, {});

                // Convierte el objeto en un array
                const categorias = Object.keys(groupedData).map(key => ({
                    nombre: key,
                    ubicaciones: groupedData[key]
                }));



                setCategorias(categorias);
            });
    }, []);

    const patiosCategorias = categorias.filter(categoria =>
        categoria.nombre.toUpperCase().startsWith("PATIO")
    );
    const otherCategorias = categorias.filter(categoria =>
        !categoria.nombre.toUpperCase().startsWith("PATIO")
    );

    return (

        <Box sx={{ height: '100%', width: '100%', display: "flex", overflow: "hidden" }}>
            {(!isMobile || open) &&
                <Box sx={{
                    width: "300px", height: "100%", boxSizing: "border-box", position: 'absolute', zIndex: 9999, color: "white", overflowY: "auto", backdropFilter: 'blur(10px)', backgroundColor: 'rgba(0,0,0, 0.3)', padding: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)'
                    // ocultar el scroll
                    , '&::-webkit-scrollbar': {
                        display: 'none'
                    },


                }}>
                    {otherCategorias.map((categoria, index) => (
                        <Accordion key={index} sx={{
                            backgroundColor: 'rgba(0,0,0,0.3)',
                            color: 'white',
                            marginBottom: '10px'
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                            >
                                <Typography sx={{ color: 'white' }}>
                                    {categoria.nombre}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {categoria.ubicaciones.map((loc, locIndex) => (
                                    <Button
                                        key={locIndex}
                                        onClick={
                                            ubicacion?.name === loc.name
                                                ? () => {
                                                    setUbicacion(null);
                                                    setOpen(false);
                                                }
                                                : () => {
                                                    setUbicacion(loc);
                                                    setOpen(false);
                                                }
                                        }
                                        sx={{
                                            width: '100%',
                                            marginBottom: '10px',
                                            color: 'white',
                                            backgroundColor: ubicacion?.name === loc.name
                                                ? 'rgba(0,0,0,0.8)'
                                                : 'rgba(0,0,0,0.3)',
                                        }}
                                        variant="contained"
                                    >
                                        {loc.name}
                                    </Button>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}

                    {/* Renderiza la sección de "Patios Gastronómicos" si hay categorías que comiencen con "PATIO" */}
                    {patiosCategorias.length > 0 && (
                        <Accordion sx={{
                            backgroundColor: 'rgba(0,0,0,0.3)',
                            color: 'white',
                            marginBottom: '10px'
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                aria-controls="patios-content"
                                id="patios-header"
                            >
                                <Typography sx={{ color: 'white' }}>
                                    PATIOS GASTRONÓMICOS
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {patiosCategorias.map((categoria, index) => (
                                    <Accordion key={index} sx={{
                                        backgroundColor: 'rgba(0,0,0,0.2)',
                                        color: 'white',
                                        marginBottom: '10px'
                                    }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                            aria-controls={`patio-panel${index}-content`}
                                            id={`patio-panel${index}-header`}
                                        >
                                            <Typography sx={{ color: 'white' }}>
                                                {categoria.nombre.slice(6, categoria.nombre.length)}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {categoria.ubicaciones.map((loc, locIndex) => (
                                                <Button
                                                    key={locIndex}
                                                    onClick={
                                                        ubicacion?.name === loc.name
                                                            ? () => {
                                                                setUbicacion(null);
                                                                setOpen(false);
                                                            }
                                                            : () => {
                                                                setUbicacion(loc);
                                                                setOpen(false);
                                                            }
                                                    }
                                                    sx={{
                                                        width: '100%',
                                                        marginBottom: '10px',
                                                        color: 'white',
                                                        backgroundColor: ubicacion?.name === loc.name
                                                            ? 'rgba(0,0,0,0.8)'
                                                            : 'rgba(0,0,0,0.3)',
                                                    }}
                                                    variant="contained"
                                                >
                                                    {loc.name}
                                                </Button>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Box>
            }
            {
                isMobile && !open &&
                <Button
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 9999,
                        color: 'white',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                    onClick={() => setOpen(!open)}
                >
                    <SearchIcon /> Buscar
                </Button>
            }
            {
                isMobile && open &&
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 9999,
                        color: 'white',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                    }}
                    onClick={() => setOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
            }
            {
                ubicacion && ((!isMobile) || (isMobile && !open)) &&
                <Box sx={{
                    right: 0, width: { xs: '100%', sm: '300px' },
                    bottom: isMobile && 0,
                    position: 'absolute', zIndex: 9999, color: "white", backdropFilter: 'blur(10px)',
                    backgroundColor: 'rgba(0,0,0, 0.3)', padding: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                    borderBottomLeftRadius: !isMobile && '16px',
                    padding: '20px',
                    boxSizing: 'border-box',
                }}>
                    <Box sx={{
                        width: '100%',
                        height: '100px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Typography sx={{
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}>{ubicacion.name}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        gap: '20px',
                        height: "70%",
                    }}>
                        {ubicacion.sitio && <Button
                            sx={{
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                width: '100%',
                                color: 'white',
                            }}
                            variant="contained"
                            onClick={() => window.location.assign(`${window.location.origin}/recorrido/${ubicacion.sitio}`)}
                        >Ver Propuesta</Button>
                        }
                        {ubicacion.patio && ubicacion.patio.propuestas.length > 0 &&
                            <Button sx={{
                                width: '100%',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                color: 'white',
                            }} variant="contained"
                                onClick={() => {
                                    setModalPatio({
                                        open: true,
                                        data: ubicacion.patio,
                                        propuestaIndex: 0,
                                    })
                                }}
                            >Shows En Vivo</Button>
                        }
                        {ubicacion.phone &&
                            <Button sx={{
                                width: '100%',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                color: 'white',
                            }} target='_blank' variant="contained" href={`tel:${ubicacion.phone}`}>Llamar</Button>
                        }
                        {ubicacion.instagram &&
                            <Button sx={{
                                width: '100%',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                color: 'white',
                            }} target='_blank' variant="contained" href={`https://www.instagram.com/${ubicacion.instagram}`}>Instagram</Button>
                        }
                        <Button sx={{
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            width: '100%',
                            color: 'white',
                        }} variant="contained"
                            href={`https://www.google.com/maps/search/?api=1&query=${ubicacion.lat},${ubicacion.lng}`}
                            target='_blank'
                        >Como llegar</Button>

                    </Box>
                </Box>
            }
            <Box sx={{ width: "100%", height: "100%" }}>
                <MapComponent ubicacion={ubicacion} />
            </Box>

            <Modal
                open={modalPatio.open}
                onClose={() => setModalPatio({ open: false, data: null })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 99999 }}
            >
                {/* Contenedor padre estático */}
                <Box
                    sx={{
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {/* Contenedor fijo para solo la imagen swipeable */}
                    <Box
                        sx={{
                            width: "90vw",
                            maxWidth: "400px",
                            position: 'relative',
                            overflow: 'hidden',
                            borderRadius: '20px',
                            height: '100%', // Asegura que el contenedor tenga altura fija
                        }}
                    >
                        <SwipeableViews
                            index={modalPatio.propuestaIndex}
                            onChangeIndex={(index) => setModalPatio({ ...modalPatio, propuestaIndex: index })}
                            enableMouseEvents
                            containerStyle={{
                                height: '100%', // Contenedor del slide ocupa la altura completa
                            }}
                            slideStyle={{
                                height: '100%', // Cada slide ocupa toda la altura
                            }}
                        >
                            {modalPatio?.data?.propuestas.map((propuesta, index) => (
                                <Box key={index} sx={{
                                    position: 'relative', width: '100%', height: '100%',
                                }}>
                                    <img
                                        src={`${API_BASE_URL.slice(0, -5)}${propuesta.imagen}`}
                                        alt={`propuesta-${index}`}
                                        style={{
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: '80vh',
                                            backgroundColor: 'black',
                                            borderRadius: '20px',
                                            userSelect: 'none',
                                        }}
                                    />
                                    {!isMobile && modalPatio.propuestaIndex > 0 && (
                                        <IconButton
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '10px',
                                                transform: 'translateY(-50%)',
                                                color: 'white',
                                                backgroundColor: 'rgba(0,0,0,0.5)',
                                            }}
                                            onClick={() => setModalPatio({ ...modalPatio, propuestaIndex: modalPatio.propuestaIndex - 1 })}
                                        >
                                            <ArrowBackIcon />
                                        </IconButton>
                                    )}
                                    {!isMobile && modalPatio.propuestaIndex < modalPatio.data.propuestas.length - 1 && (
                                        <IconButton
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                right: '10px',
                                                transform: 'translateY(-50%)',
                                                color: 'white',
                                                backgroundColor: 'rgba(0,0,0,0.5)',
                                            }}
                                            onClick={() => setModalPatio({ ...modalPatio, propuestaIndex: modalPatio.propuestaIndex + 1 })}
                                        >
                                            <ArrowForwardIcon />
                                        </IconButton>
                                    )}

                                </Box>
                            ))}
                        </SwipeableViews>
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                color: 'white',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                            }}
                            onClick={() => setModalPatio({ open: false, data: null })}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {/* Controles de puntos debajo del contenedor swipeable */}
                    <Box
                        sx={{
                            bottom: 20,
                            display: 'flex',
                            gap: '10px',
                            position: "absolute"
                        }}
                    >
                        {modalPatio?.data?.propuestas.map((_, index) => (
                            <Box
                                key={index}
                                sx={{
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    cursor: 'pointer',
                                    backgroundColor: modalPatio.propuestaIndex === index ? 'white' : 'rgba(255, 255, 255, 0.5)',
                                }}
                                onClick={() => setModalPatio({ ...modalPatio, propuestaIndex: index })}
                            />
                        ))}
                    </Box>
                </Box>
            </Modal>
        </Box >
    );
}