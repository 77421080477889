import React, { useState } from 'react';
import { AppBar, Toolbar, Box, IconButton, Button, Menu, MenuItem, useMediaQuery, Skeleton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAppContext } from '../contexts/AppContext';
import Logo from '../assets/logo.png';
import Sidebar from './Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';


// import { Canvas, useFrame } from '@react-three/fiber';
// import { useLoader } from '@react-three/fiber';
// import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
// import { DoubleSide, TextureLoader } from 'three';
// import Model3d from '../3D/myObject.fbx';
// import textureImage from '../3D/shaded.png';
import routes from '../routes/routes';
// import Skeleton from '@mui/material';

// const Model = ({ size = 0.005 }) => {
//     const model = useLoader(FBXLoader, Model3d);
//     const texture = useLoader(TextureLoader, textureImage);

//     const modelRef = useRef();

//     // Rotación continua del modelo
//     useFrame(() => {
//         if (modelRef.current) {

//             modelRef.current.rotation.y += 0.01 * (modelRef.current.direction || 1)

//             if (modelRef.current.rotation.y >= 0.1) {
//                 modelRef.current.direction = -1
//             } else if (modelRef.current.rotation.y <= -0.4) {
//                 modelRef.current.direction = 1
//             }

//         }
//     });

//     React.useEffect(() => {
//         if (model) {
//             model.traverse((child) => {
//                 if (child.isMesh) {
//                     child.material.map = texture;
//                     child.material.needsUpdate = true;
//                     child.material.side = DoubleSide;
//                 }
//             });
//         }
//     }, [model, texture]);

//     return <primitive ref={modelRef} object={model} scale={size} />;
// };


export default function Header(props) {
    const { height } = props;
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const isMobile = useMediaQuery('(max-width: 600px)');

    const { logged, login, loading, logout, userData } = useAppContext();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        logout();
    };

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Box id="header" sx={{ position: "fixed", width: "100%", zIndex: 90000 }}>
            <AppBar position="static" sx={{
                backgroundColor: "rgba(34,0,21,0.8)",
                backdropFilter: "blur(10px)",
                height: height,
            }}>
                <Toolbar sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "100%",
                    backgroundColor: "transparent",
                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 3,
                    }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {isMobile &&
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="primary"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                    onClick={() => setOpen(true)}
                                >
                                    <MenuIcon />
                                </IconButton>
                            }
                            <img src={Logo} alt="Logo" style={{ height: 80 }} />
                        </Box>
                        {!isMobile &&
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 3,
                                }}
                            >
                                {routes.map((route, index) => (
                                    <Button
                                        key={index}
                                        variant="contained"
                                        onClick={() => navigate(route.path)}
                                        sx={{
                                            backgroundColor: 'transparent',
                                            border: location.pathname === route.path ? '2px solid #D4A646' : 'none',
                                            color: '#FFFFFF',
                                            borderRadius: '8px',
                                            height: '50px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {route.title}
                                        {route.path === "/en-vivo" &&
                                            <Box sx={{
                                                width: "10px",
                                                height: "10px",
                                                backgroundColor: "red",
                                                borderRadius: "50%",
                                                marginLeft: "10px",
                                                animation: "pulse 2s infinite",


                                            }} />
                                        }
                                    </Button>
                                ))}
                            </Box>
                        }
                    </Box>
                    {/* <Box sx={{
                        width: "100px",
                        height: "100%",
                    }}>
                        <Canvas camera={{ position: [2, 2, 5], fov: 20 }}>
                            <ambientLight intensity={1} />
                            <directionalLight position={[100, 10, 10]} />
                            <Suspense fallback={null}>
                                <Model size={0.01} />
                            </Suspense>
                        </Canvas>
                    </Box> */}
                    {!isMobile && loading ?
                        <Box sx={{
                        }}>
                            <Button
                                sx={{
                                    color: "white",
                                    textTransform: "none",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                }}
                            >
                                <Skeleton variant="rectangular" width={200} height={40} />
                            </Button>
                        </Box>
                        :
                        logged &&
                        <Box sx={{
                        }}>
                            <Button
                                sx={{
                                    color: "white",
                                    textTransform: "none",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                }}
                                onClick={handleMenu}
                            >
                                <AccountCircle sx={{ mr: 1 }} />
                                {userData.Representado?.RdoNombre || (userData.Nombre + ' ' + userData.Apellido)}
                            </Button>

                            {userData.CUIL &&
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        sx: {
                                            width: anchorEl ? anchorEl.clientWidth : 'auto',
                                        },
                                    }}
                                >
                                    <MenuItem disabled>{`${userData.CUIL.slice(0, 2)}-${userData.CUIL.slice(2, 10)}-${userData.CUIL.slice(10)}`}</MenuItem>
                                    <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
                                </Menu>
                            }
                        </Box>
                    }
                </Toolbar>
            </AppBar>
            <Sidebar open={open} setOpen={setOpen} />
        </Box >
    );
}