import { Box, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './layout/Layout';
import routes from './routes/routes';
import theme from './theme';
import { AppProvider } from './contexts/AppContext';
import PrivateRoute from './routes/PrivateRoute';
import { AlertaProvider } from './contexts/AlertaContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <AlertaProvider>
            <Router>
              <Layout>
                <Routes>
                  {routes.map((route, index) => (
                    route.private ? (
                      <Route key={index} path={route.path} element={<PrivateRoute element={route.element} />} />
                    ) : (
                      <Route key={index} path={route.path} element={route.element} />
                    )
                  ))}
                  <Route path="*" element={<Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={10}
                    fontSize="2rem"
                    color={'white'}
                  >
                    404
                  </Box>} />
                </Routes>
              </Layout>
            </Router>
          </AlertaProvider>
        </AppProvider>
      </ThemeProvider>
    </QueryClientProvider >
  );
}

export default App;
