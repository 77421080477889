import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import Mapa from "./mapa/Mapa";
import { useEffect, useState } from "react";
import Sponsor1 from "./assets/tonadita.PNG";
import Sponsor2 from "./assets/pepsi.PNG";

export default function Recorrido() {
    const [first, setFirst] = useState(true);
    const isMobile = useMediaQuery('(max-width:600px)');

    const sponsors = [Sponsor2, Sponsor1];
    const [currentSponsorIndex, setCurrentSponsorIndex] = useState(Math.floor(Math.random() * sponsors.length));


    useEffect(() => {

        document.title = "Recorrido Peñero";
    }, []);

    const downloadPliego = () => {
        // el pliego esta en ./pleigo
        window.open("/pliego.pdf");
    }

    useEffect(() => {
        const sponsorTimer = setInterval(() => {
            setCurrentSponsorIndex((prevIndex) => (prevIndex + 1) % sponsors.length);
        }, 5000); // Cambia cada 5 segundos
        return () => clearInterval(sponsorTimer);
    }, []);

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            px: "10px",
            alignItems: 'center',
            boxSizing: 'border-box',
            gap: '20px',
        }}
        >

            <Box sx={{
                width: '100%',
                height: window.innerHeight - 100,
                mt: '10px',
                overflow: 'hidden',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                zIndex: 1,
            }}>
                {
                    first &&
                    <Box sx={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        height: '100%',
                        width: '100%',
                        backdropFilter: 'blur(10px)',
                        zIndex: 99999,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        pt: { xs: "130px", sm: "200px" },
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        cursor: 'pointer',
                        px: { xs: '20px', sm: '20px' },
                        boxSizing: 'border-box',
                    }}
                        onClick={() => setFirst(false)}
                    >
                        <Typography variant="h4" sx={{
                            textAlign: 'center',
                            mt: '20px',
                            fontWeight: { sm: 'bold' },
                        }}>
                            Mapa Recorrido Peñero
                        </Typography>
                        <Typography variant="h6" sx={{
                            textAlign: 'center',
                            fontSize: { xs: '18px', sm: '24px' },
                        }}>
                            Encontrá el espacio que más te guste y disfrutá de la mejor música.
                        </Typography>
                        <Typography variant="h6" sx={{
                            textAlign: 'center',
                            fontSize: { xs: '16px', sm: '24px' },
                            mt: '20px',
                        }}>
                            {isMobile ?
                                "Pulsa para ver el recorrido." :
                                "Hacé click para ver el recorrido."
                            }
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: { xs: '40px', sm: "100px" },
                            alignItems: 'center',
                            position: 'absolute',
                            bottom: '150px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            height: { xs: '100px', sm: '130px' },
                            maxWidth: { xs: '200px', sm: '280px' },
                            animation: 'fadeIn 1s',
                        }}>
                            <img src={sponsors[currentSponsorIndex]} alt="sponsor" style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain',
                            }} />
                        </Box>
                    </Box>
                }
                <Mapa />
            </Box>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                {/* <Button variant="contained" sx={{
                    backgroundColor: 'rgba(255,255,255,0.1)',
                    color: '#FFFFFF',
                    borderRadius: '8px',
                    px: '20px',
                    py: '10px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    my: '20px',
                    backdropFilter: 'blur(10px)',
                    "&:hover": {
                        backgroundColor: '#D4A646',
                    }

                }}
                    onClick={() => setOpen(true)}
                >
                    Tutoriales
                </Button> */}
                {/* <Button variant="contained" sx={{
                    backgroundColor: 'rgba(255,255,255,0.1)',
                    color: '#FFFFFF',
                    borderRadius: '8px',
                    px: '20px',
                    py: '10px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    my: '20px',
                    backdropFilter: 'blur(10px)',
                    "&:hover": {
                        backgroundColor: '#D4A646',
                    }

                }}
                    onClick={() => downloadPliego()}
                >
                    Ver convocatoria
                </Button> */}
                {/* {!logged &&
                    <Button variant="contained" sx={{
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        color: '#FFFFFF',
                        borderRadius: '8px',
                        px: '20px',
                        py: '10px',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        my: '20px',
                        backdropFilter: 'blur(10px)',
                        "&:hover": {
                            backgroundColor: '#D4A646',
                        }

                    }}
                        onClick={login}
                    >
                        Iniciar Sesión
                    </Button>
                } */}
            </Box>
            {/* <Typography variant="h6" sx={{ textAlign: 'center' }}>
                La convocatoria ya ha cerrado.
            </Typography> */}

            {/* {logged ? loading ?
                <CircularProgress />
                :
                <Formulario />
                :
                <></>
            } */}
            {/* <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                disableAutoFocus
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    maxWidth: '90%',
                    boxSizing: 'border-box',
                    backgroundColor: 'rgba(255,255,255,0.6)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: 1,
                    boxShadow: 24,
                    p: 4,

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mt: '20px',
                    }}>
                        <Box sx={{
                            borderRadius: '8px',
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
                            px: '10px',
                            py: '20px',
                            my: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255,255,255,0.8)',
                        }}>

                            <Typography variant="body1" gutterBottom sx={{ color: "black" }}>
                                Tutorial para completar el formulario
                            </Typography>
                            <Button variant="contained" sx={{

                            }}
                                onClick={() => window.open("/tutorial.mp4")}
                            >
                                Ver Tutorial
                            </Button>
                        </Box>
                        <Box sx={{
                            borderRadius: '8px',
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
                            px: '10px',
                            py: '20px',
                            my: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255,255,255,0.8)',
                        }}>
                            <Typography variant="body1" gutterBottom sx={{ color: "black", textAlign: "center" }}>
                                Tutorial para agregar un representado con CIDI
                            </Typography>

                            <Button variant="contained" sx={{


                            }}
                                onClick={() => window.open("https://secretariadeambiente.cba.gov.ar/wp-content/uploads/2018/05/Administrador-de-relaciones.pdf")}
                            >
                                Ver Tutorial
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal > */}
        </Box >
    );
}