import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppContext } from '../contexts/AppContext';

const PrivateRoute = ({ element }) => {
    // const { logged } = useAppContext();

    return element

    // return (
    //     logged ? element : <Navigate to="/" />
    // )
};

export default PrivateRoute;