import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { getUserData } from '../services/usuarios';
import { useNavigate } from 'react-router-dom';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [formularioRecorrido, setFormularioRecorrido] = useState(null);
    const [loading, setLoading] = useState(false);
    const [navigateRecorrido, setNavigateRecorrido] = useState(false);
    async function login() {
        window.location.assign('https://cidi.cba.gov.ar/Cuenta/Login?app=637');
    }

    function logout() {
        Cookies.remove('CiDi', { path: '/', domain: '.villamaria.gob.ar' });
        setUserData(null);
        window.location.assign('https://cidi.cba.gov.ar/Cuenta/CerrarSesion');
    }

    // function getCidiCookie() {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const cidiParam = urlParams.get('cidi');

    //     if (cidiParam) {
    //         Cookies.set('CiDi', cidiParam, { expires: 1, path: '/', domain: '.villamaria.gob.ar' });
    //         setNavigateRecorrido(true);
    //         return cidiParam;
    //     }

    //     const cidiCookie = Cookies.get('CiDi');

    //     if (cidiCookie) {
    //         return cidiCookie;
    //     }
    // }

    // const checkCidiCookie = useCallback(async () => {
    //     const cidiCookie = getCidiCookie();

    //     if (cidiCookie) {
    //         setLoading(true);
    //         let response = await getUserData({ cidi: cidiCookie });
    //         setUserData(response.usuario);

    //         if (response.formulario) {
    //             setFormularioRecorrido(response.formulario);
    //         }

    //         setLoading(false);
    //     } else {
    //         setUserData(null);
    //         setLoading(false);
    //     }
    // }, []);

    // useEffect(() => {
    //     checkCidiCookie();
    // }, [checkCidiCookie]);

    const logged = userData !== null;

    return (
        <AppContext.Provider value={{
            logged,
            login,
            logout,
            loading,
            userData,
            formularioRecorrido,
            navigateRecorrido,
            setNavigateRecorrido
        }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    return useContext(AppContext);
};