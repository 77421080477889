import { Box } from "@mui/material";
import { useEffect, useState } from "react";

export default function Background() {
    const [stars, setStars] = useState([]);



    const backgorundColor = "#220015"
    const starColor = "#D4A646"

    useEffect(() => {
        const generateStars = () => {
            const starsArray = [];
            for (let i = 0; i < 60; i++) {
                starsArray.push({
                    left: Math.random() * 100 + '%',
                    top: Math.random() * 100 + '%',
                    size: Math.random() * 3 + 1 + 'px',
                    animationDelay: Math.random() * 5 + 's'
                });
            }
            setStars(starsArray);
        };

        generateStars();
    }, []);

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            backgroundColor: backgorundColor,
            position: "absolute",
            zIndex: -1
        }}>
            {stars.map((star, index) => (
                <Box
                    key={index}
                    sx={{
                        position: 'absolute',
                        borderRadius: '50%',
                        backgroundColor: starColor,
                        width: star.size,
                        height: star.size,
                        left: star.left,
                        top: star.top,
                        animation: `twinkle 3s infinite`,
                        animationDelay: star.animationDelay,

                    }}
                />
            ))}
        </Box>
    )
}